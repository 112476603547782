import * as React from "react";
import {Spinner} from "react-bootstrap";
// @ts-ignore
import * as style from './Loading.module.scss';
import {FC} from "react";

type LoadingProps = {
    type: string,
    message: string,
    variant?: string
}

const Loading:FC<LoadingProps> = ({type, message, variant}) => {
    return (
        <div className={"w-100 h-100 d-flex justify-content-center flex-column align-items-center pt-5 mt-5 mb-5"}>
            <Spinner animation={type} variant={variant} />
            <span className={"pt-3"}>
                 {message}
            </span>
        </div>
    )
}
export default Loading;
